<template>
  <div>
    <app-header />
    <main-content />
    <app-footer />
  </div>
</template>

<script>
  import AppHeader from '../components/RoadrunnerLanding/AppHeader'
  import MainContent from '../components/RoadrunnerLanding/MainContent'
  import AppFooter from '../components/RoadrunnerLanding/AppFooter'

  export default {
    name: 'RoadrunnerLanding',

    components: {
      AppHeader,
      MainContent,
      AppFooter,
    },
  }
</script>
