<template>
  <main class="main-content container">
    <section class="section sec-one" id="roadrunner">
      <div>
        <h2>
          <strong
            >Your time is valuable, spend more of it enjoying the things you
            love.</strong
          >
        </h2>
        <h5>
          We're taking the time out of shopping and running the road for you.
        </h5>
      </div>
      <transition name="fade">
        <div v-show="showSecOneImg">
          <transition name="fade">
            <img src="../../assets/kitchen.png" v-show="showKitchenImg" />
          </transition>
          <transition name="fade">
            <img src="../../assets/family.png" v-show="showFamilyImg" />
          </transition>
          <transition name="fade">
            <img src="../../assets/kitchen2.png" v-show="showKitchenTwoImg" />
          </transition>
        </div>
      </transition>
    </section>

    <section class="section service" id="service">
      <h1>Welcome to RoadRunner!</h1>

      <div class="service__content">
        <div>
          <h3>Service</h3>
          <p>
            We are an online shopping marketplace working very hard to bring you
            an all-inclusive luxury shopping experience. Shop from your
            favourite local retailers through our mobile app and have your items
            delivered directly to your door.
          </p>
          <p>
            Looking for a gift? We have you covered! With our premium gifting
            services, we will have your gift thoughtfully wrapped, paired with a
            card and delivered directly to the recipient.
          </p>
          <div
            class="service__download-section service__download-section--desktop"
          >
            <img class="service__download" src="../../assets/dow_apple.png" />
            <img class="service__download" src="../../assets/dow_google.png" />
          </div>
        </div>

        <div class="service__circle">
          <img src="../../assets/iPhone.png" />
        </div>

        <div
          class="service__download-section service__download-section--mobile"
        >
          <img class="service__download" src="../../assets/dow_apple.png" />
          <img class="service__download" src="../../assets/dow_google.png" />
        </div>
      </div>
    </section>

    <section class="section business-model" id="business-model">
      <div class="business-model__left">
        <img class="business-model__left-bg" src="../../assets/Polygon_2.png" />
        <p>
          <span
            v-for="item in businessModelList"
            :key="item.title"
            @click="selectBusinessModel(item)"
            :class="{
              'business-model__selected-title':
                item.title === businessModelSelected.title,
            }"
          >
            <img
              v-if="item.title === businessModelSelected.title"
              class="business-model__left-logo"
              src="../../assets/R_logo_green.png"
            />
            {{ item.title }}
            <br />
          </span>
        </p>
      </div>

      <div class="business-model__right">
        <h2>Business Model</h2>
        <transition name="fade">
          <span>
            <h5>{{ businessModelSelected.title }}</h5>
            <p>{{ businessModelSelected.text }}</p>
          </span>
        </transition>
      </div>

      <div class="business-model__mobile">
        <h2>Business Model</h2>

        <el-collapse>
          <el-collapse-item title="No mark-ups" name="1">
            <div class="business-model__collapse-text">
              Never pay markup on your order again. Customers should not be
              up-charged for purchasing items for delivery instead of in-store.
              We assure you that all prices listed by retailers on our platform
              will match the retailers in-store and online prices.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Supporting each other locally" name="2">
            <div class="business-model__collapse-text">
              At RoadRunner we believe in working hard to serve our community.
              Our focus is on providing top notch services to customers, while
              supporting local businesses and offering competitive employment
              opportunities.
            </div>
          </el-collapse-item>
          <el-collapse-item title="No hidden fees" name="3">
            <div class="business-model__collapse-text">
              Customers pay the retail cost of ordered items, a delivery fee
              which includes a standard cost per store and applicable taxes.
            </div>
          </el-collapse-item>
          <el-collapse-item title="No commission fees" name="4">
            <div class="business-model__collapse-text">
              No commission fees charged to business partners or drivers. Yes,
              you read that correctly.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Customer service" name="5">
            <div class="business-model__collapse-text">
              We care about our customers and our partners. During business
              hours there will always be a live agent that can be reached by
              phone or email to resolve any questions or concerns.
            </div>
          </el-collapse-item>
          <el-collapse-item title="Quality improvement" name="6">
            <div class="business-model__collapse-text">
              As we continue to expand, we will build on our progress by
              implementing changes based on feedback to ensure we provide the
              best possible experience to our customers and partners.
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </section>

    <section class="section contact" id="contact">
      <div class="contact__left">
        <h2>Contact</h2>
        <h4>Connect with us:</h4>
        <p>
          If you are a business interested in learning more about partnering
          with RoadRunner and having your merchandise listed in our marketplace
          or you are an experienced driver looking for a unique employment
          opportunity, please send us an email at
          <a href="mailto:info@runningtheroad.ca">info@runningtheroad.ca</a> or
          call us at <strong>647 394 5101</strong>.
        </p>
      </div>

      <div class="contact__right">
        <img src="../../assets/launch_box2.png" class="contact__desktop-img" />
        <img src="../../assets/launch_box3.png" class="contact__mobile-img" />
      </div>
    </section>
  </main>
</template>

<script>
  export default {
    name: 'MainContent',

    data() {
      return {
        showKitchenImg: true,
        showFamilyImg: false,
        showKitchenTwoImg: false,
        showSecOneImg: false,
        businessModelList: [
          {
            title: 'No mark-ups',
            text: 'Never pay markup on your order again. Customers should not be up-charged for purchasing items for delivery instead of in-store. We assure you that all prices listed by retailers on our platform will match the retailers in-store and online prices.',
          },
          {
            title: 'Supporting each other locally',
            text: 'At RoadRunner we believe in working hard to serve our community. Our focus is on providing top notch services to customers, while supporting local businesses and offering competitive employment opportunities.',
          },
          {
            title: 'No hidden fees',
            text: 'Customers pay the retail cost of ordered items, a delivery fee which includes a standard cost per store and applicable taxes.',
          },
          {
            title: 'No commission fees',
            text: 'No commission fees charged to business partners or drivers. Yes, you read that correctly.',
          },
          {
            title: 'Customer service',
            text: 'We care about our customers and our partners. During business hours there will always be a live agent that can be reached by phone or email to resolve any questions or concerns.',
          },
          {
            title: 'Quality improvement',
            text: 'As we continue to expand, we will build on our progress by implementing changes based on feedback to ensure we provide the best possible experience to our customers and partners.',
          },
        ],
        businessModelSelected: {},
      }
    },

    created() {
      this.businessModelSelected = this.businessModelList[0]
    },

    mounted() {
      this.runImagesCarousel()
    },

    methods: {
      runImagesCarousel() {
        const that = this
        setTimeout(() => {
          that.showSecOneImg = true
        }, 1000)

        setInterval(() => {
          if (that.showKitchenImg) {
            that.showKitchenImg = false
            setTimeout(() => {
              that.showFamilyImg = true
            }, 33)
          }

          if (that.showFamilyImg) {
            that.showFamilyImg = false
            setTimeout(() => {
              that.showKitchenTwoImg = true
            }, 33)
          }

          if (that.showKitchenTwoImg) {
            that.showKitchenTwoImg = false
            setTimeout(() => {
              that.showKitchenImg = true
            }, 33)
          }
        }, 6000)
      },

      selectBusinessModel(item) {
        this.businessModelSelected = item
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main-content {
  }

  .sec-one {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    padding-top: 250px;
    min-height: 704px;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
      padding-top: 112px;
      align-items: center;

      div {
        max-width: 519px;
      }
    }

    h2 {
      font-family: CabinetGrotesk-Light;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 74px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 565px;
      margin-bottom: 52px;

      @media (max-width: 1200px) {
        font-family: CabinetGrotesk-Regular;
        font-size: 36px;
        font-weight: 400;
        line-height: 45px;
        margin-bottom: 28px;
        margin-top: 20px;
      }

      strong {
        font-family: CabinetGrotesk-Regular;
        font-weight: 400;
      }
    }

    h5 {
      font-family: Mulish;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      max-width: 515px;
      color: #42636c;

      @media (max-width: 1200px) {
        font-size: 21px;
        line-height: 30px;
      }
    }

    img {
      position: relative;
      top: -132px;
      right: -24px;

      @media (max-width: 1200px) {
        top: unset;
        max-width: 100%;
      }
    }
  }

  .service {
    margin-top: 0;

    .service__content {
      display: flex;
      justify-content: space-between;

      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
    }

    h1 {
      font-family: CabinetGrotesk-Regular;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 72px;

      @media (max-width: 1200px) {
        font-size: 36px;
        line-height: 45px;
        max-width: 519px;
        margin: 0 auto;
        margin-bottom: 56px;
      }
    }

    h3 {
      font-family: CabinetGrotesk-Light;
      font-size: 36px;
      font-style: normal;
      font-weight: 300;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 24px;

      @media (max-width: 1200px) {
        font-size: 24px;
      }
    }

    p {
      font-family: Mulish;
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 32px;
      max-width: 519px;

      @media (max-width: 1200px) {
        font-size: 16px;
        line-height: 30px;
      }
    }

    .service__download-section {
      @media (max-width: 1200px) {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }

    .service__download-section--mobile {
      display: none;

      @media (max-width: 1200px) {
        display: flex;
        margin-top: 48px;
      }
    }

    .service__download-section--desktop {
      @media (max-width: 1200px) {
        display: none;
      }
    }

    .service__download {
      margin-top: 32px;
      margin-right: 8px;
      cursor: pointer;

      @media (max-width: 1200px) {
        margin-top: 0px;
        width: 136px;
      }
    }

    .service__circle {
      width: 582.51px;
      height: 582.51px;
      border-radius: 50%;
      background: #f3f4fd;
      position: relative;

      @media (max-width: 1200px) {
        width: 321px;
        height: 321px;
      }

      img {
        top: -12px;
        left: 138px;
        position: relative;

        @media (max-width: 1200px) {
          width: 167px;
          top: -9px;
          left: 77px;
        }
      }
    }
  }

  .business-model {
    display: flex;
    justify-content: space-between;

    .business-model__left {
      position: relative;
      color: #c6dde3;
      min-width: 700px;
      height: 700px;
      padding-top: 189px;
      padding-left: 120px;
      margin-left: -24px;

      @media (max-width: 1200px) {
        display: none;
      }

      .business-model__left-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }

      .business-model__left-logo {
        width: 46px;
        position: relative;
        top: 18px;
        left: -10px;
        margin-left: -26px;
      }

      .business-model__selected-title {
        font-family: CabinetGrotesk-Extrabold;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 542px;
        color: #c6dde3;
        top: -10px;
        position: relative;
      }

      p {
        font-family: CabinetGrotesk-Extralight;
        font-size: 24px;
        font-style: normal;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 542px;
        color: #fff;
        margin: 0;

        span {
          transition: 0.25s all;
          cursor: pointer;

          &:hover {
            color: #c6dde3;
            font-weight: 800;
          }
        }
      }
    }

    .business-model__right {
      padding-top: 61px;

      @media (max-width: 1200px) {
        display: none;
      }

      h2 {
        font-family: CabinetGrotesk-Regular;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 56px;
      }

      h5 {
        font-family: CabinetGrotesk-Light;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 20px;
      }

      p {
        font-family: Mulish;
        font-size: 21px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 446px;
      }
    }
  }

  .business-model__mobile {
    display: none;

    @media (max-width: 1200px) {
      display: block;
      width: 100%;
      max-width: 519px;
      margin: 0 auto;
    }

    h2 {
      font-family: CabinetGrotesk-Regular;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 45px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 56px;
    }
  }

  .business-model__collapse-text {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin: 16px 0 32px 0;
  }

  .contact {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      padding-top: 0;
    }

    .contact__left {
      padding-top: 110px;

      h2 {
        font-family: CabinetGrotesk-Regular;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: left;

        @media (max-width: 1200px) {
          font-size: 36px;
          line-height: 45px;
          margin-bottom: 56px;
        }
      }

      h4 {
        font-family: CabinetGrotesk-Light;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        letter-spacing: 0em;
        text-align: left;
        margin: 56px 0 18px 0;

        @media (max-width: 1200px) {
          font-size: 24px;
        }
      }

      p {
        font-family: Lato;
        font-size: 21px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 500px;
        margin: 0;

        @media (max-width: 1200px) {
          font-size: 16px;
          line-height: 30px;
        }

        strong {
          font-weight: 500;
        }
      }

      a {
        text-decoration: none;
        font-weight: 500;
        color: #000;
      }
    }

    .contact__right {
      right: -24px;
      position: relative;

      @media (max-width: 1200px) {
        right: unset;
        margin-top: 24px;
      }
    }

    .contact__desktop-img {
      @media (max-width: 1200px) {
        display: none;
      }
    }

    .contact__mobile-img {
      display: none;

      @media (max-width: 1200px) {
        display: block;
      }
    }
  }
</style>

<style lang="scss">
  .business-model__mobile {
    * {
      border: none !important;
    }

    .el-collapse-item__header {
      font-family: CabinetGrotesk-Light;
      font-size: 21px;
      font-style: normal;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;

      &.is-active {
        color: #1f7a8c;
        font-family: CabinetGrotesk-Medium;
        font-size: 22px;
        line-height: 72px;
      }
    }

    .el-collapse-item__arrow {
      color: #1f7a8c;
      font-weight: normal;
    }
  }

  .fade-enter-active {
    transition: opacity 0.88s;
  }
  .fade-enter {
    opacity: 0;
  }
</style>
